
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getSearch, IStreams, IPagination, ISearchParam } from "./search";
import streams from "./search";
import moment from "moment";

export default defineComponent({
  name: "Search",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Search");
    });
    const filterTanggal = ref([]);
    const startSearch = ref(false);
    const loading = ref(false);
    const isNull = ref(false);
    const streamsData = ref<Array<IStreams>>(streams);
    const pagination = ref<IPagination>({
      page: 1,
      total: 0,
      limit: 10,
    });
    const searchParam = ref<ISearchParam>({
      from_date: "",
      to_date: "",
      source: "",
      q: "",
      q_field: "",
      page: 1,
      items: 10,
    });
    const getData = async () => {
      startSearch.value = true;
      loading.value = true;
      let params = searchParam.value;
      if (filterTanggal.value.length > 0) {
        params.from_date = moment(filterTanggal.value[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        params.to_date = moment(filterTanggal.value[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (searchParam.value.source == "all" || searchParam.value.source == "") {
        delete params.source;
      }
      if (searchParam.value.q == "") {
        delete params.q;
      }
      if (searchParam.value.q_field == "") {
        delete params.q_field;
      }
      let response = await getSearch(params);
      streamsData.value = response["result"].streams;
      for (let i = 0; i < response["result"].streams.length; i++) {
        streamsData.value[i].show_all = false;
      }
      pagination.value = response["result"].page_info[0];
      loading.value = false;
    };
    const setItemsPerPage = (event) => {
      pagination.value.limit = parseInt(event.target.value);
      getData();
    };
    const currentPageChange = (val) => {
      searchParam.value.page = val;
      getData();
    };
    const dataMedia = ref([
      {
        id: 1,
        name: "Media Cetak",
        value: "media_cetak",
      },
      {
        id: 2,
        name: "Media Online",
        value: "news",
      },
      {
        id: 3,
        name: "Media TV",
        value: "media_tv",
      },
    ]);
    const dataFieldPencarian = ref([
      {
        id: 1,
        name: "Judul Berita",
        value: "title",
      },
      {
        id: 2,
        name: "Konten Berita",
        value: "content",
      },
    ]);
    const defaultTime = [
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1, 23, 59, 59),
    ];
    const filterTanggalShortcut = ref([
      {
        text: "Hari Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
      {
        text: "Kemarin",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(start.getDate() - 1);
          start.setHours(0, 0, 0);
          end.setDate(end.getDate() - 1);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Seminggu Lalu",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(start.getDate() - 7);
          start.setHours(0, 0, 0);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Bulan Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(1);
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
      {
        text: "Bulan Lalu",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setDate(1);
          start.setMonth(start.getMonth() - 1);
          start.setHours(0, 0, 0);
          end.setDate(0);
          end.setHours(23, 59, 59);
          return [start, end];
        },
      },
      {
        text: "Tahun Ini",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setMonth(0);
          start.setDate(1);
          start.setHours(0, 0, 0);
          return [start, end];
        },
      },
    ]);
    const setDate = (date) => {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    };
    return {
      startSearch,
      loading,
      pagination,
      filterTanggal,
      searchParam,
      streamsData,
      getData,
      setDate,
      setItemsPerPage,
      currentPageChange,
      dataMedia,
      dataFieldPencarian,
      defaultTime,
      isNull,
      filterTanggalShortcut,
    };
  },
});
