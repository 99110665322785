import axios from "axios";

interface IStreams {
  account: string;
  content: string;
  date: string;
  id_account: string;
  journalist: string;
  origin_url: string;
  portal: string;
  source: string;
  thumbnail: string;
  timestamp: string;
  title: string;
  url: string;
  _id: string;
  show_all:boolean;
}

interface IPagination {
  page: number;
  total: number;
  limit: number;
}

interface ISearchParam {
  from_date: string;
  to_date: string;
  source?: string;
  q?: string;
  q_field?: string;
  page:number;
  items:number;
}

const streams: Array<IStreams> = [];

export { IStreams, IPagination,ISearchParam };

export default streams;

export const getSearch = async (formdata) => {
  let data = {};
  await axios
    .get("https://scsv.onlinemonitoring.id/search", { params: formdata })
    .then((res) => {
      if (res.status == 200) {
        data = { result: res.data, message: "Berhasil" };
      } else {
        data = { result: null, message: "Terjadi Kesalahan", error: true };
      }
    })
    .catch((err) => {
      data = { result: null, message: "Terjadi Kesalahan", error: true };
    });

  return data;
};
